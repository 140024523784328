import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br /> I'm Sean Haile.
        </BigTitle>
        <Subtitle>I am a full stack web developer and cybersecurity engineer.</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="NBA Players App"
            link="http://nba-players-app.surge.sh"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            Combines my two loves, tech and sports! Log in and create, read, edit, or delete images and info of your favorite players.
          </ProjectCard>
          <ProjectCard
            title="I'm Here"
            link="http://imhere.surge.sh"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            Web application linking professionals (i.e. painters or movers) with those in need of professional services.
          </ProjectCard>
          <ProjectCard
            title="Browser Router"
            link="http://blue-amount.surge.sh"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            Sample React Browser Router website developed for Healthcare Consultancy company.
          </ProjectCard>
          <ProjectCard
            title="Sushi-Zo"
            link="http://uxwdi-sushizo.surge.sh/"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            Revamped webpage of a local high-end sushi restaraunt created by a team of four developers and four UX designers!
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Sean Haile" />
          <AboutSub>
          Outside of tech, I enjoy photography, reading, running, bicycle riding, playing basketball, and spending time with my family.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
        My tech stack; API, Auth (SAML/OAuth/Bcrypt), Azure, Bootstrap, CORS, Express, Git/GitHub, HTML, JavaScript, Node.js, npm, PingIdentity, PostgresSQL, Postman, React.js, REST, Ruby, Surge, TDD, & Visual Studio Code.
        </AboutDesc>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            Say <a href="mailto:seanhaile.codes@gmail.com">Hi</a> or find me on other platforms:{' '}
            <a href="https://www.linkedin.com/in/sean-haile-nyc/">LinkedIn</a> &{' '}
            <a href="https://twitter.com/SeanHaile1">Twitter</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2024 {' '}
          <a href="https://github.com/seanhailecodes/">GitHub Repository</a>. Have a great day!
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
