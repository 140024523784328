const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', 
  siteTitle: 'Sean - Portfolio', 
  siteTitleAlt: 'Sean', 
  siteTitleShort: 'Sean', 
  siteHeadline: 'Web Develoer and Technical Solutions',
  siteUrl: 'https://goofy-thompson-85382f.netlify.com/', 
  siteLanguage: 'en', 
  siteLogo: '/logo.png',
  siteDescription: 'Playful & Colorful One-Page website with Parallax effect',
  author: 'Sean',
  userTwitter: '@seanhaile1', 
  ogLanguage: 'en_US', 
  googleAnalyticsID: 'UA-47519312-5',
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
}
